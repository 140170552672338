import { template as template_78c91359f9274827b96b720a17436732 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_78c91359f9274827b96b720a17436732(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
