import { template as template_47bec13f8c214e29a6ef9f854a3cfc9a } from "@ember/template-compiler";
const FKLabel = template_47bec13f8c214e29a6ef9f854a3cfc9a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
