import { template as template_3a48c1a331fa463d9a9b0ee26e0b0cc5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3a48c1a331fa463d9a9b0ee26e0b0cc5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
