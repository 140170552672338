import { template as template_7ab4d4802cd24cc6b1f884d67c5087b3 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7ab4d4802cd24cc6b1f884d67c5087b3(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
