import { template as template_be8a8f6f0af34b3b8b8a3cc0455f2fe8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_be8a8f6f0af34b3b8b8a3cc0455f2fe8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
