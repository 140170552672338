import { template as template_2827fa152ea94ad486e60bf22496d252 } from "@ember/template-compiler";
const WelcomeHeader = template_2827fa152ea94ad486e60bf22496d252(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
